
export interface GremlinQueryResult {
    type: string;
    value: any[];
}

export class ProjectedValues {
    type: string;
    value: any[];

    constructor(type: string, value: any[]) {
        this.type = type;
        this.value = value;
    }

    static fromJSON(json: any): ProjectedValues {
        if (json['@type'] !== 'g:Map') throw Error('Invalid gremlin query result format.');
        return new ProjectedValues(json['@type'], json['@value']);
    }

    readValues(keys: string[]): Record<string, any> {
        const map = new Map<string, any>();
        const foundKeys = new Set<string>();
        for (let i = 0; i < this.value.length; i += 2) {
            const key = this.value[i];
            let value = this.value[i + 1];
            if (keys.includes(key)) {
                if (isObject(value)) {
                    value = value['@value'];
                }
                map.set(key, value);
                foundKeys.add(key);
            }
        }
        // Check if all requested keys were found
        const missingKeys = keys.filter(key => !foundKeys.has(key));
        if (missingKeys.length > 0) {
            console.error(`The following requested keys do not exist in the Gremlin result fields: ${missingKeys.join(', ')}`);
            throw new Error(`The following requested keys do not exist in the Gremlin result fields: ${missingKeys.join(', ')}`);
        }
        return Object.fromEntries(map.entries());
    }
}

export class GremlinQueryResultClass implements GremlinQueryResult {
    type: string;
    value: any[];

    constructor(type: string, value: any[]) {
        this.type = type;
        this.value = value;
    }

    static fromJSON(jsonInput: any): GremlinQueryResultClass {
        let jsonObject;

        // If the input is a string, try to parse it
        if (typeof jsonInput === 'string') {
            try {
                jsonInput = jsonInput.replace('\n', '');
                jsonObject = JSON.parse(jsonInput);
            } catch (error) {
                console.error("Failed to parse JSON string:", error);
                throw new Error("Invalid JSON string provided");
            }
        } else {
            throw new Error("Invalid Gremlin query result format: Not a valid string");
        }

        if (!jsonObject['@type'] || !jsonObject['@value']) {
            jsonObject = JSON.parse(jsonObject);
            if(!jsonObject['@type'] || !jsonObject['@value'])
                throw new Error(`Invalid Gremlin query result format: 
                Does not contain @type or @value ${jsonObject}, ${jsonInput}`);
        }

        return new GremlinQueryResultClass(jsonObject['@type'], jsonObject['@value']);
    }

    readValues(keys?: string[]): any {
        console.log(this.type, this.value);
        if (this.type === 'g:List') {
            if (this.value.length > 0 && this.value[0]['@type'] === 'g:Map') {
                // Complex case: list of maps
                return this.value.map((item: any) => {
                    const projectedValues = ProjectedValues.fromJSON(item);
                    return projectedValues.readValues(keys || []);
                });
            } else {
                // Simple case: list of primitive values
                return this.value;
            }
        } else {
            throw new Error('Unsupported Gremlin result type');
        }
    }
}

function isObject(value: any): boolean {
    return value !== null && typeof value === 'object';
}