import * as React from "react";
import Toggle from "@amzn/awsui-components-react-v3/polaris/toggle";
import Icon from "@amzn/awsui-components-react-v3/polaris/icon";
import AppContext from "../../context/AppContext";

const DashboardVisibilityToggle = ({model}) => {
    console.log('Model:', model);
    console.log('model.manualUserAcceptance:', model.manualUserAcceptance);
    console.log('Type of model.manualUserAcceptance:', typeof model.manualUserAcceptance);

    let initialValue = false;
    if (typeof model.manualUserAcceptance === 'string') {
        try {
            // If it's a JSON string, parse it
            const parsedValue = JSON.parse(model.manualUserAcceptance);
            if (typeof parsedValue === 'object' && parsedValue['user-acceptance'] !== undefined) {
                initialValue = parsedValue['user-acceptance'] === 'true' || parsedValue['user-acceptance'] === true;
            } else {
                initialValue = parsedValue === 'true' || parsedValue === true;
            }
        } catch (e) {
            // If not JSON, check if it's a string 'true' or 'false'
            initialValue = model.manualUserAcceptance === 'true';
        }
    } else {
        // If it's already a boolean
        initialValue = !!model.manualUserAcceptance;
    }
    console.log('Initial value:', initialValue);
    const { modsWorkflowManagementClient } = React.useContext(AppContext);
    const [checked, setChecked] = React.useState(initialValue);
    const [isLoading, setIsLoading] = React.useState(false);

    // Add useEffect to monitor state changes
    React.useEffect(() => {
        console.log('Checked state changed to:', checked);
    }, [checked]);

    const handleToggleChange = async ({ detail }) => {
        setIsLoading(true);
        setChecked(detail.checked);
        updateDashboardVisibility(model, detail.checked)
            .then(resp => {
                setChecked(detail.checked);
                setIsLoading(false);
            })
            .catch(err => {
                console.error("Error updating dashboard visibility:", err);
                setChecked(!detail.checked);
                setIsLoading(false);
            });
    };

    const updateDashboardVisibility = async (model, visible) => {
        console.log(`Updating dashboard visibility for model ${model.experimentId} to ${visible}`);
        const id = model.experimentId.split(":").pop();
        const acceptanceCriteria = `{"user-acceptance": ${visible}}`
        return modsWorkflowManagementClient.publishIsModelPerformanceAcceptableCognito(id, acceptanceCriteria, model.region);
    }

    return (
        <Toggle
            onChange={handleToggleChange}
            checked={checked}
            disabled={isLoading}
        >
            Published to AMLET
            {isLoading && <span> <Icon
                name="lock-private"
                size="small"
                variant="disabled"
            /></span>}
        </Toggle>
    );
}

export default DashboardVisibilityToggle;