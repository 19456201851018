import React, { useEffect } from 'react';
import {
    AppLayout,
    BreadcrumbGroup,
    Tabs
} from "@amzn/awsui-components-react-v3/polaris";
import AppContext from "../../context/AppContext";
import SideBar from "../../navigation/sidebar";
import { getSidebarItemFromPageStage } from "../home/home";
import AMLETSearchContainer from "./AMLETSearchContainer";
import AMLETExperimentResults from "./AMLETSearchResults";
import AMLETHelpPanel from "./AMLETHelpPanel";
import { GetMyModelsResult } from "./AMLETGremlinQueryHelper";
import {ALL_EXPERIMENTS, USER_ID, YOUR_EXPERIMENTS} from "../../constants/amlet_constants";



const CreateAMLETDashboard = () => {
    const [activeTabId, setActiveTabId] = React.useState(
        ALL_EXPERIMENTS
    );
    const { modsWorkflowManagementClient } = React.useContext(AppContext);

    const [ myModelsResultsData, setMyModelsResultsData ] = React.useState(null);

    useEffect(() => {
        if (activeTabId === YOUR_EXPERIMENTS) {
            const user = localStorage.getItem(USER_ID) || "";
            GetMyModelsResult(modsWorkflowManagementClient, user)
                .then(data => {
                    console.log("setting MyModelsResultData to empty []");
                    setMyModelsResultsData([]);

                    console.log("setting MyModelsResultData to data");
                    console.log(data);
                    setMyModelsResultsData(data);
                })
                .catch(error => {
                    console.error('Error fetching AMLET experiments:', error);
                    setMyModelsResultsData(null);
                });
        }
    }, [activeTabId, modsWorkflowManagementClient]);

    return (
        <div>
            <BreadcrumbGroup
                items={[
                    { text: "AMLET", href: "#" },
                    { text: "Experiments", href: "#" }
                ]}
                ariaLabel="Breadcrumbs"
            />
            <Tabs
                onChange={({ detail }) =>
                    setActiveTabId(detail.activeTabId)
                }
                activeTabId={activeTabId}
                tabs={[
                    {
                        label: "All Experiments",
                        id: ALL_EXPERIMENTS,
                        content:
                            <div>
                                <AMLETSearchContainer></AMLETSearchContainer>
                            </div>
                    },
                    {
                        label: "Your Experiments",
                        id: YOUR_EXPERIMENTS,
                        content:
                            <div>
                                <AMLETExperimentResults
                                    searchData={myModelsResultsData}
                                    isLoading={!myModelsResultsData}
                                    isYourExperiments={true}>
                                </AMLETExperimentResults>
                            </div>
                    }
                ]}
            />

        </div>
    );
};

const AMLETDashboardAppLayout = () => {
    const { pageStage } = React.useContext(AppContext);

    return (
        <AppLayout
            content={<CreateAMLETDashboard />}
            navigation={<SideBar activeHref="/amlet" items={getSidebarItemFromPageStage(pageStage)} />}
            tools={<AMLETHelpPanel />}
        />
    );
};

class AMLETDashboard extends React.Component<any, any> {
    static contextType = AppContext;


    render() {
        document.body.classList.add('awsui-visual-refresh');
        return <AMLETDashboardAppLayout />;
    }
}

export default AMLETDashboard;